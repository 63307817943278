import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";

import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle";

import broadcast from "assets/img/broadcast.jpg";
import motionPictures from "assets/img/motion_pictures.jpg";
import videoGames from "assets/img/video_games.jpg";
import onDemand from "assets/img/on_demand.jpg";

const useStyles = makeStyles(blogsStyle);

export default function SectionSolutions({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={`${classes.mlAuto} ${classes.mrAuto}`}
            >
              <h2 className={`${classes.title} ${classes.textCenter}`}>
                Solutions for your industry workflow
              </h2>
              <br />
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <img src={broadcast} alt="..." />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${broadcast})`,
                          opacity: "1",
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${broadcast})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h3 className={classes.cardTitle}>Broadcast</h3>
                    <p className={classes.description}>
                      Designed for the constantly in-motion broadcast industry
                      where to-the-second scheduling rules. From airdate
                      schedules to delivery dates, ON-AIR Pro provides
                      intelligent management of your promo workflow.
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <img src={motionPictures} alt="..." />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${motionPictures})`,
                          opacity: "1",
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${motionPictures})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h3 className={classes.cardTitle}>Motion Pictures</h3>
                    <p className={classes.description}>
                      TRACE is the leading product of its kind for creating and
                      tracking the production workflow of feature/theatrical
                      trailers, gaming trailers, teasers, home entertainment and
                      creative spots for TV.
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <img src={videoGames} alt="..." />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${videoGames})`,
                          opacity: "1",
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${videoGames})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h3 className={classes.cardTitle}>Video Games</h3>
                    <p className={classes.description}>
                      Trailers for video games have unique requirements that
                      TRACE and ON-AIR Pro can help with tracking and reporting.
                      Fulfillment was never so easy.
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
              <Card plain blog className={classes.card}>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={4}>
                    <CardHeader image plain>
                      <img src={onDemand} alt="..." />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${onDemand})`,
                          opacity: "1",
                        }}
                      />
                      <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${onDemand})`,
                          opacity: "1",
                        }}
                      />
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8}>
                    <h3 className={classes.cardTitle}>Non-Linear</h3>
                    <p className={classes.description}>
                      The video delivery systems change rapidly as technology
                      evolves. Keep your productions on schedule and adjust to
                      industry norms with grace and ease.
                    </p>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
